export const throttle = (callback, threshold) => {
    let suppress = false;
    const clear = () => {
        suppress = false;
    };

    return () => {
        if (!suppress) {
            callback.apply(this, arguments);
            window.setTimeout(clear, threshold);
            suppress = true;
        }
    }
};
