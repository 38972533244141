'use strict';

import '../sass/main.scss';

import FastClick from 'fastclick';
import { Dollar as $ } from './polygon/dollar';
import { loadModules } from './modules';

const doc = document; // eslint-disable-line
const log = $.createLogger('Main', 'main');

log('starting fastclick', doc.body);
FastClick.attach(doc.body);

log('loading modules');
loadModules();
