import { Dollar as  $ } from '../polygon/dollar';

let $$form = null;
let $$wrapper = null;

export const useEditImageForm = () => $.ready(init);

function init() {
    $$form = $('.fjs-edit-image-form');
    $$wrapper = $$form.closest('.fjs-async');

    $('.fjs-edit-image ').on('click', loadForm);
    $$form.on('submit', onFormSubmit);

    hideForm();
}

function onFormSubmit(event) {
    event && event.preventDefault();
    const data = {};

    data.id = $$form.find('.fjs-id').val();
    data.meta = $$form.find('.fjs-tags').val();

    $.ajax({
        method: 'post',
        url: '/ajax/image/save',
        data: data,
        success: onImageSaved,
        error: onError,
    });
}

function onImageSaved(response) {
    if (response.status === 200) {
        const data = response.responseText;
        const json = JSON.parse(data);

        if (json === null) {
            onError();
        } else {
            $.publish('data/update/image', json);
            hideForm();
            $.publish('dialog/close');
        }
    } else {
        onError();
    }
}

function loadForm(event) {
    event && event.preventDefault();
    hideForm();

    var imageId = $(this).attr('data-image-id');

    $.ajax({
        method: 'get',
        url: '/ajax/image/' + imageId,
        success: onImageDataLoaded,
        error: onError,
    });
}

function onError() {
    $.publish('dialog/close');
}

function onImageDataLoaded(response) {
    if (response.status === 200) {
        const data = response.responseText;
        const json = JSON.parse(data);

        if (json === null) {
            console.logg('image not found!');
           onError();
        } else {
            populateForm(json);
            showForm();
        }
    } else {
        onError();
    }
}

function populateForm(data) {
    $$form.find('.fjs-id').val(data.id);
    $$form.find('.fjs-tags').val(data.meta || '');
}

function hideForm(event) {
    event && event.preventDefault();

    toggleForm(false);
}

function showForm(event) {
    event && event.preventDefault();

    toggleForm(true);
}

function toggleForm(isOpen) {
    if (isOpen) {
        $$wrapper.removeClass('is-loading');
    } else {
        $$wrapper.addClass('is-loading');
    }
}
