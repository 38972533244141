import { Dollar as $ } from '../polygon/dollar';

const log = $.createLogger('SetPoster', 'setposter');

export const useSetPoster = () =>$.ready(init);

function init() {
	const $buttons = $('.fjs-set-poster');

	if ($buttons.length) {
		log('set-poster buttons found; starting interaction');
		$buttons.on('click', onSetPoster);
	}
}

function onSetPoster(event) {
	event.preventDefault();

	const $this = $(this);
	const $image = $this.closest('.fjs-image');
	const imageId = $image.attr('data-image-id');
	const url = $this.attr('data-action-url');

	$.ajax({
		url,
		method: 'post',
		data: {
			imageId,
		},
		type:'json',
		success: onSetPosterSuccess.bind($image),
		error: onSetPosterFailed.bind($image),
	});
}

function onSetPosterSuccess(data) {
	log('poster is set completed', data);
	const $this = $(this);
	if (data.success) {
		closeDialog($this);
	} else {
		log('unable to set poster: ', data.reason);
	}
}

function onSetPosterFailed(data) {
	log('set poster failed', data);
	//closeDialog($(this));
}

function closeDialog($image) {
	const toggle = $image.find('.fjs-toggle')[0];

	toggle.checked = false;
}
