import { Dollar as $ } from '../polygon/dollar';

const log = $.createLogger('WebSearch', 'websearch');

export const useAddFromWebSearch = () => $.ready(init);

function init() {
	const $buttons = $('.fjs-web-search-add');

	if ($buttons.length) {
		log('websearch add buttons found; starting interaction');
		$buttons.on('click', onAddResultFromWebSearchClicked);
	}
}

function onAddResultFromWebSearchClicked(event) {
	event.preventDefault();

	const $this = $(this);
	const $item = $this.parent('.fjs-item');
	const ajaxUrl = $this.attr('href');

	$.ajax({
		url: ajaxUrl,
		method: 'get',
		type:'json',
		success: onImageAddSuccess.bind($item),
		error: onImageAddFailed.bind($item),
	});

	$item.find('.fjs-web-search-add').addClass('js-hidden');
	$item.find('.fjs-web-search-progress-label').removeClass('js-hidden');
	$item.find('.fjs-web-search-success-label').addClass('js-hidden');
}

function onImageAddSuccess(data) {
	log('data loaded', data);

	if (data.success === true) {
		const $this = $(this);
		$this.find('.fjs-web-search-add').addClass('js-hidden');
		$this.find('.fjs-web-search-progress-label').addClass('js-hidden');
		$this.find('.fjs-web-search-success-label').removeClass('js-hidden');
	} else {
		onImageAddFailed(data);
	}
}

function onImageAddFailed(data) {
	log('failed', data);

	const $this = $(this);
	$this.find('.fjs-web-search-add').removeClass('js-hidden');
	$this.find('.fjs-web-search-progress-label').addClass('js-hidden');
	$this.find('.fjs-web-search-success-label').addClass('js-hidden');
}
