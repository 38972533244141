/* eslint-disable no-alert */
'use strict';

import { Dollar as $ } from "../polygon/dollar";

let $img = null;
let $checkbox;

export const useViewHires = () => $.ready(init);

function init() {
	$img = $('.fjs-hires-img');
	$checkbox = $('.fjs-hires-toggle');

	if ($img.length && $checkbox.length) {
		$('.fjs-hires-image').on('click', showHiresImage);
	}
}

function showHiresImage(event) {
	const imageUrl = $(this).attr('data-hires');

	$img.attr('src', imageUrl);
	$checkbox.get(0).checked = true;

	if (event.preventDefault) {
		event.preventDefault();
	}
}
