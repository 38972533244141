'use strict';

import { Dollar as $ } from '../polygon/dollar';

let $direct = null;
let $proxy = null;

export const useProxyImage = () => $.complete(init);

function init() {
	$direct = $('.fjs-image-direct');
	$proxy = $('.fjs-image-proxy');

	const img = $direct.get(0);

	if (!img) return;

	if (img.complete === true && img.naturalWidth === 0) {
		switchImages();
	}
}

function switchImages() {
	var proxyUrl = $proxy.attr('data-src');

	$direct.addClass('js-hidden').removeClass('js-active');
	$proxy.attr('src', proxyUrl).removeClass('js-hidden').addClass('js-active').on('load', function() {
		$.publish('MODULE/proxy-image/load');
	});

	$.publish('MODULE/proxy-image/switch');
}
