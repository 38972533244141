import { Dollar as $ } from '../polygon/dollar';

export const useDataViews = () => init();

function init() {
	$.subscribe('data/update/image', onImageUpdate);
}

function onImageUpdate(imageData) {
	const selector = '.fjs-image[data-image-id="' + imageData.id + '"]';
	const $image = $(selector);

	$image.find('.fjs-tags').text(imageData.meta);
}
