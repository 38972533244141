import { useAddFromWebSearch } from './add-from-web-search';
import { useConfirmationDialog } from './confirm';
import { useDataViews } from './data-views';
import { useDialog } from './dialog';
import { useDuplicates } from './duplicates';
import { useEditImageForm } from './edit-image-form';
import { useLabelSiblingCloser } from './label-sibling-closer';
import { useProxyImage } from './proxy-image';
import { useRegenerateThumbnail } from './regen-thumb';
import { useSearchFormHelper } from './search-form-helpable';
import { useSetPoster } from './set-poster';
import { useViewHires } from './view-hires';
import { useSizeDetect } from './size-detect';

export const loadModules = () => {
    useConfirmationDialog();
    useViewHires();
    useSizeDetect();
    useProxyImage();
    useDialog();
    useEditImageForm();
    useDataViews();
    useLabelSiblingCloser();
    useDuplicates();
    useAddFromWebSearch();
    useSearchFormHelper();
    useRegenerateThumbnail();
    useSetPoster();
};

