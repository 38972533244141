import { Dollar as $ } from '../polygon/dollar';

const log = $.createLogger('Form Helpable', 'helpableform');

export const useSearchFormHelper = () => $.ready(init);

function init() {
    const $forms = $('.fjs-form-helpable');

    if ($forms.length > 0) {
        log('found helpable forms');
        $forms.each(extendForm);
    }
}

function extendForm(form) {
  const $form = $(form);
  const $buttons = $form.find('.fjs-search-form-helper');
  const $field = $form.find('.fjs-search-form-helpee');

  $buttons.each(button => extendSearchFormButton($field, button));
}

function extendSearchFormButton($field, button) {
    const $button = $(button);
    $button.on('click', onSearchFormButtonClicked.bind(button, $field));
}

function onSearchFormButtonClicked($field, event) {
    event.preventDefault();

    log('button clicked', this);
    const $this = $(this);
    const suggestionText = $this.text().trim();
    const fieldText = $field.val().trim();
    const currentSearchTerms = fieldText.split(' ');

    if (currentSearchTerms.indexOf(suggestionText) === -1) {
        $field.val(fieldText + ' ' + suggestionText);
    }
}
