import { Dollar as $ } from '../polygon/dollar';

export const useLabelSiblingCloser = () => $.ready(init);

function init() {
    $('.fjs-label-closer').on('click', handleClick);
}

function handleClick() {
    const $this = $(this);
    const idToFind = $this.siblings('label').attr('for');
    const $checkbox = $('input[type=checkbox]#' + idToFind);

    if ($checkbox.length !== 0) {
        $checkbox.get(0).checked = false;
    }
}
