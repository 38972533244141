import { Dollar as $ } from '../polygon/dollar';

const log = $.createLogger('RegenThumb', 'regenthumb');

export const useRegenerateThumbnail = () =>$.ready(init);

function init() {
	const $buttons = $('.fjs-regen-thumb');

	if ($buttons.length) {
		log('regen-thumb buttons found; starting interaction');
		$buttons.on('click', onRegenThumb);
	}
}

function onRegenThumb(event) {
	event.preventDefault();

	const $this = $(this);
	const $image = $this.closest('.fjs-image');
	const url = $this.attr('href');

	$.ajax({
		url: url,
		method: 'get',
		type:'json',
		success: onRegenCompleted.bind($image),
		error: onRegenFailed.bind($image),
	});
}

function onRegenCompleted(data) {
	log('regen completed', data);

	const $this = $(this);
	const $preview = $this.find('.fjs-preview-image');
	const imageUrl = data.url + '?t=' + new Date().getTime();

	log('preview', $preview);

	$preview.attr('src', imageUrl);
	closeDialog($this);
}

function onRegenFailed(data) {
	log('regen failed', data);
	closeDialog($(this));
}

function closeDialog($image) {
	const toggle = $image.find('.fjs-toggle')[0];

	toggle.checked = false;
}
