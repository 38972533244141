/* eslint-disable no-alert */
'use strict';

import { Dollar as $ } from "../polygon/dollar";

export const useConfirmationDialog = () => $.ready(init);

function init() {
	$('.fjs-confirm').on('click', requireConfirmation);
}

function requireConfirmation(event) {
	const message = $(this).attr('data-confirm-message');
	const confirmation = confirm(message);

	if (!confirmation) {
		event.preventDefault();
	}
}
