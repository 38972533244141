'use strict';

import { Dollar as $ } from '../polygon/dollar';

let $$dialog = null;

export const useDialog = () => $.ready(init);

function init() {
	$$dialog = $('.fjs-dialog');
	$.subscribe('dialog/close', closeDialog);
	$.subscribe('dialog/open', openDialog);
	$('.fjs-dialog-close').on('click', closeDialog);
	$('.fjs-dialog-open').on('click', openDialog);
}

function closeDialog(event) {
	event && event.preventDefault();

	toggleDialog(false);
}

function openDialog(event) {
	event && event.preventDefault();

	toggleDialog(true);
}

function toggleDialog(isOpen) {
	if (isOpen) {
		$$dialog.addClass('js-open');
	} else {
		$$dialog.removeClass('js-open');
	}
}
