import { Dollar as $ } from '../polygon/dollar';
import { throttle } from '../utils/throttle';

let $status = null;

export const useSizeDetect = () => $.ready(init);

function init() {
	$status = $('.fjs-detect-size-text');

	start();
	update();
}

function start() {
	const handler = throttle(update, 50);

	$.resize(handler);
	$.complete(handler);
	$.subscribe('MODULE/proxy-image/load', handler);

	handler();
}

function update() {
	const $img = $('.fjs-detect-size-image.js-active');
	const width = $img.dim().width;

	if (!$img.get(0)) return;

	const nWidth = $img.get(0).naturalWidth;
	const zoom = Math.floor(width / nWidth * 100);

	if (!isNaN(zoom) && zoom !== Infinity) {
		$status
			.removeClass('js-hidden')
			.text('Size: ' + zoom + '%');
	}
}
