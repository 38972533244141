import { Dollar as $ } from '../polygon/dollar';

const log = $.createLogger('SizeChecker', 'sizechecker');
const ratioThreshold = 10;
const cssLargest = 'js-is-largest';
const cssShowClass = 'js-is-shown';

let $container = null;
let $info = null;
let $imageList = null;
let $suspicious = null;
let $countNumber = null;
let $countSuffix = null;
let currentLargest = 0;
let $images = null;

export const useDuplicates = () => $.ready(init);

function init() {
    $container = $('.fjs-size-container');
    $info = $('.fjs-same-size-info');
    $suspicious = $('.fjs-suspicious-info');
    $imageList = $('.fjs-imagelist');
    $countNumber= $('.fjs-count-number');
    $countSuffix = $('.fjs-count-suffix');
    $images = $container.find('img');

    if ($images.length > 0) $images.each(processImage);
}

function processImage(image) {
    if (image.complete) {
        log('image was loaded before');
        onImageLoaded.apply(image);
    } else {
        log('attaching eventListener');
        image.addEventListener('load', onImageLoaded);
    }
}

function updateSameSizeInfo() {
    if (!$info) return;
    if ($images.length === 1) return;

    log('updating same size info');

    var $items = $container.find('.fjs-size-item');
    var allItemsAreTheSame = true;

    $items.each((item) => {
        var $item = $(item);

        if (!$item.hasClass(cssLargest)) {
            allItemsAreTheSame = false;
        }
    });

    log('all items are the same:', allItemsAreTheSame);

    if (allItemsAreTheSame) {
        if (!$info.hasClass(cssShowClass)) {
            $info.addClass(cssShowClass);
        }
    } else {
        $info.removeClass(cssShowClass);
    }
}

function updateRatioInfo() {
    const $items = $container.find('.fjs-size-item');
    const firstImg = $items.find('.fjs-size-img').get(0);
    const firstRatio = firstImg.naturalWidth / firstImg.naturalHeight;
    let offsetDetected = false;

    $items.each((item) => {
        const $item = $(item);
        const img = $item.find('.fjs-size-img').get(0);
        const currentRatio = img.naturalWidth /  img.naturalHeight;
        const ratioDifference = firstRatio / currentRatio;
        const offset = Math.abs(1 - ratioDifference) * 100;
        log('offset found:', offset);

        if (offset >= ratioThreshold) {
            log('offset threshold reached (' + ratioThreshold + ')');
            offsetDetected = true;
            highlightSuspiciousItem($item);
        }
    });

    if (!offsetDetected) {
        $suspicious.removeClass('js-is-shown');
    }
}

function highlightSuspiciousItem($item) {
    log('highlighting!');
    if (!$suspicious.hasClass('js-is-shown')) {
        $suspicious.addClass('js-is-shown');
        log('adding class!');
    }

    $item.find('.fjs-suspicious-item-info').addClass('js-is-shown');
}

function removeItem($item) {
    if (!$imageList || !$item) return;

    $item.remove();
    currentLargest = 0;
    $images = $container.find('img');

    const imageIds = [];

    $container.find('.fjs-size-item')
        .each((item) => {
            const $item = $(item);
            $item.removeClass(cssLargest);

            const id = $item.attr('data-image-id');
            imageIds.push(id);
            testForSize($item);
        });

    $imageList.val(imageIds.join(','));
    updateRatioInfo();
    updateSameSizeInfo();
    updateCountInfo();
}

function updateCountInfo() {
    $countNumber.text($images.length);

    if ($images.length === 1) {
        $countSuffix.addClass('hidden');
    } else {
        $countSuffix.removeClass('hidden');
    }
}

function onImageLoaded() {
    log('------------------------------------');
    log('image loaded');
    const image = this;
    const $image = $(image);
    const $parentItem = $image.closest('.fjs-size-item');
    const $label = $parentItem.find('.fjs-size-label');
    const $removeButton= $parentItem.find('.fjs-remove-from-list');
    const sizes = {
        width: image.naturalWidth,
        height: image.naturalHeight,
    };

    $label.text(sizes.width + ' x ' + sizes.height);

    $removeButton.on('click', (event) => {
        event.preventDefault();
        removeItem($parentItem);
    });

    testForSize($parentItem);
    updateSameSizeInfo();
    updateRatioInfo();
    log('all done');
}

function testForSize($item) {
    const image = $item.find('img').get(0);

    const sizes = {
        width: image.naturalWidth,
        height: image.naturalHeight,
    };

    const surface = sizes.width * sizes.height;
    log('testing for size', sizes);

    if (surface >= currentLargest) {
        log('new larger size found');

        if (surface > currentLargest) {
            log('actually larger');

            $container.find('.' + cssLargest).removeClass(cssLargest);
            currentLargest = surface;
        }

        $item.addClass(cssLargest);
    }
}
